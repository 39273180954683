import useIntersectionObserver from '@react-hook/intersection-observer';
import React, { useRef } from 'react';
import Link from 'next/link';
import { useCustomer } from '@/app/context/CustomerContext/useCustomer';
import Image from 'next/image';
import { useAnalytics } from '@/app/context/AnalyticsContext/useAnalytics';
import {
  CloudArrowUpIcon,
  LockClosedIcon,
  FingerPrintIcon,
  Cog6ToothIcon,
  ServerIcon,
  PhotoIcon
} from '@heroicons/react/24/solid';

import foreignLanguageBlackImage from 'public/foreign_language_black.png';
import historyBlackImage from 'public/history_black.png';
import medicineBlackImage from 'public/medicine_black.png';
import computerScienceImage from 'public/computer_science.png';
import biologyBlackImage from 'public/biology_black.png';

import sampleScreenImage from 'public/sample_screen.png';

export default function MarketingContent() {
  const containerRef = useRef();
  const lockRef = useRef(false);
  const { isIntersecting } = useIntersectionObserver(containerRef);
  if (isIntersecting && !lockRef.current) {
    lockRef.current = true;
  }

  const { trackEvent, analyticsInstance } = useAnalytics();

  const { isProEligible } = useCustomer();

  return (
    <>
      {' '}
      {/* Logo cloud */}
      <section className="bg-white py-8">
        <div className="container m-8 mx-auto max-w-5xl">
          <h1 className="my-2 w-full px-5 text-center text-5xl font-bold leading-tight text-gray-800">
            Stop wasting time making flashcards.
          </h1>
          <div className="mb-4 w-full">
            <div className="gradient mx-auto my-0 h-1 w-64 rounded-t py-0 opacity-25"></div>
          </div>
          <div className="flex flex-wrap">
            <div className="flex w-5/6 flex-col justify-center p-6 sm:w-1/2">
              <h2 className="mb-3 text-3xl font-bold leading-none text-gray-800">
                Upload a wide variety of files
              </h2>
              <p className="mb-8 text-gray-600">
                Limbiks can quickly generate flashcards from PDFs, PowerPoint
                presentations, documents, and even pictures of notes or text!
                Simply upload your file, and a complete set of flashcards will
                be ready in seconds. Additionally, Limbiks can create flashcards
                from YouTube videos and Wikipedia articles.
                <br />
                <br />
                <span
                  onClick={() =>
                    trackEvent(
                      analyticsInstance,
                      'home_marketing_click_examples'
                    )
                  }
                  className="text-blue"
                >
                  <Link href="/examples">Click Here to see examples!</Link>
                </span>
              </p>
            </div>
            <div className="w-full p-6 sm:w-1/2">
              <Image
                width={616}
                height={496}
                alt={'Person uploading files'}
                src="/undraw_researching.svg"
              />
            </div>
          </div>
          <div className="flex flex-col-reverse flex-wrap sm:flex-row">
            <div className="mt-6 w-full p-6 sm:w-1/2">
              <Image
                width={616}
                height={496}
                alt={'Person waiting seconds to start studying'}
                src="/undraw_speed_test.svg"
              />
            </div>
            <div className="mt-6 flex w-full flex-col justify-center p-6 sm:w-1/2">
              <div className="align-middle">
                <h2 className="mb-3 text-3xl font-bold leading-none text-gray-800">
                  Start Studying in seconds
                </h2>
                <p className="mb-8 text-gray-600">
                  Limbiks provides easy to use study tools so you can start
                  studying your flashcards immediately. Flashcards can also be
                  downloaded and imported into{' '}
                  <span className="font-bold">Anki</span>,{' '}
                  <span className="font-bold">Quizlet</span>,{' '}
                  <span className="font-bold">Tinycards</span>,{' '}
                  <span className="font-bold">Cram</span>, and many other study
                  tools!
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-wrap">
            <div className="flex w-5/6 flex-col justify-center p-6 sm:w-1/2">
              <h2 className="mb-3 text-3xl font-bold leading-none text-gray-800">
                Supports 20+ Languages
              </h2>
              <p className="mb-8 text-gray-600">
                Limbiks supports 21 languages including{' '}
                <b>
                  Catalan, Chinese, Croatian, Danish, Dutch, English, Finnish,
                  French, German, Greek, Italian, Japanese, Korean, Lithuanian,
                  Macedonian, Polish, Portuguese, Romanian, Russian, Spanish,
                  Swedish, and Ukranian.
                </b>
              </p>
            </div>
            <div className="w-full p-6 sm:w-1/2">
              <Image
                width={825}
                height={591}
                alt={'Person with a globe'}
                src="/undraw_world.svg"
              />
            </div>
          </div>
        </div>
        <div className="mx-auto mt-24 mb-24 bg-white px-6 lg:px-8">
          <h2 className="text-center text-lg font-semibold leading-8 text-gray-900">
            Trusted by students for every subject
          </h2>
          <div className="mx-auto mt-8 grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-12 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 sm:gap-y-14 lg:mx-0 lg:max-w-none lg:grid-cols-5">
            <Image
              alt="Foreign Language"
              src={foreignLanguageBlackImage}
              layout="intrinsic"
              className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
            />
            <Image
              alt="History"
              src={historyBlackImage}
              layout="intrinsic"
              className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
            />
            <Image
              alt="Medicine"
              src={medicineBlackImage}
              layout="intrinsic"
              className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
            />
            <Image
              alt="Computer Science"
              src={computerScienceImage}
              layout="intrinsic"
              className="col-span-2 max-h-12 w-full object-contain sm:col-start-2 lg:col-span-1"
            />
            <Image
              alt="Biology"
              src={biologyBlackImage}
              layout="intrinsic"
              className="col-span-2 col-start-2 max-h-12 w-full object-contain sm:col-start-auto lg:col-span-1"
            />
          </div>
        </div>
      </section>
      <section className="bg-white py-8">
        <div
          style={{ maxWidth: 'calc(120vh)' }}
          className="container mx-auto flex flex-wrap pt-4 pb-12"
        >
          <div
            ref={containerRef}
            className="video"
            style={{
              flex: 1,
              position: 'relative',
              paddingBottom: '56.25%' /* 16:9 */,
              height: 0
            }}
          >
            {lockRef.current && (
              <iframe
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%'
                }}
                src={`https://www.youtube.com/embed/cLd2xItfoK8`}
                frameBorder="0"
              />
            )}
          </div>
        </div>
      </section>
      <section className="bg-white py-8">
        {/* Feature section */}

        <div className="mt-1 sm:mt-16">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto max-w-2xl sm:text-center">
              <h2 className="text-base font-semibold leading-7 text-primary">
                Everything you need
              </h2>
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                Powerful study tools at your fingertips
              </p>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                Whether you need to memorize flashcards, read through a study
                guide, or take a practice test before an exam, Limbiks has
                everything you need.
              </p>
            </div>
          </div>
          <div className="relative overflow-hidden pt-16">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
              <Image
                alt="App screenshot"
                src={sampleScreenImage}
                className="mb-[-12%] rounded-xl shadow-2xl ring-1 ring-gray-900/10"
              />
              <div aria-hidden="true" className="relative">
                <div className="absolute -inset-x-20 bottom-0 bg-gradient-to-t from-white pt-[7%]" />
              </div>
            </div>
          </div>
          <div className="mx-auto mt-16 max-w-7xl px-6 sm:mt-20 md:mt-24 lg:px-8">
            <dl className="mx-auto grid max-w-2xl grid-cols-1 gap-x-6 gap-y-10 text-base leading-7 text-gray-600 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3 lg:gap-x-8 lg:gap-y-16">
              {features.map((feature) => (
                <div key={feature.name} className="relative pl-9">
                  <dt className="inline font-semibold text-gray-900">
                    <feature.icon
                      aria-hidden="true"
                      className="absolute left-1 top-1 h-5 w-5 text-primary"
                    />
                    {feature.name}
                  </dt>{' '}
                  <dd className="inline">{feature.description}</dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </section>
    </>
  );
}

const features = [
  {
    name: 'Multiple Choice Questions.',
    description:
      'Generate full exams from your study materials. Get score reports and study tips for every test.',
    icon: CloudArrowUpIcon
  },
  {
    name: 'Study Guides.',
    description:
      'Summarize and review your files with study guides. Print or download for whenever you need them.',
    icon: LockClosedIcon
  },
  {
    name: 'Image Occlusion Generator.',
    description:
      'Automatically extract images and build Image Occlusion flashcards from your files.',
    icon: PhotoIcon
  },
  {
    name: 'Spaced Repition.',
    description:
      'Efficiently memorize all of your flashcards using our advanced spaced repetition tool.',
    icon: FingerPrintIcon
  },
  {
    name: 'Hints and Explanations.',
    description:
      'Get help whenever you need it for every flashcard and multiple choice question.',
    icon: Cog6ToothIcon
  },
  {
    name: 'Download and Sync.',
    description:
      'Download flashcards to use with any study tool or sync to Anki with one click. ',
    icon: ServerIcon
  }
];
